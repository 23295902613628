import React from "react";
import PagesSection from "./PagesSection";

const PagesList = (props) => {
  const pages = props.pages.map((page) => {
    return (
      <div>
        <PagesSection
          key={page.id}
          page={page}
          page_select={props.page_select}
          user={props.user}
          user_access={props.user_access}
        />
      </div>
    );
  });

  return <div>{pages}</div>;
};

export default PagesList;
