import React from 'react'
import { Form, Button, Modal, Message, Checkbox, Divider } from 'semantic-ui-react'

class PageDelete extends React.Component {
  constructor(props) {
  super(props);
  this.state = {title: this.props.page.title, 
                restrict_to_admin: this.props.page.restrict_to_admin || false,
                junction_page: this.props.page.junction_page || false
               };
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })


  handleChange(event) {
    this.setState({title: event.target.value});
  }


  handleSubmit = (event) => {
    event.preventDefault();
    const params = {
      Item: {
        id: this.props.page.id,
        index_number: this.props.page.index_number,
        page_id: this.props.page.page_id,
        timestamp: this.props.page.timestamp,
        title: this.state.title,
        restrict_to_admin: this.state.restrict_to_admin,
        junction_page: this.state.junction_page,
        junction_data: JSON.stringify([{title: "Default", id: "", destinsation: "" }])
      }
    };
    this.props.updatePage(params, this.props.user)
    this.setState({ modalOpen: false })
  }

  errorMessage() {
    return (
      <div>
        <Message negative>
          <Message.Header>Unable to delete</Message.Header>
          <p>The text entered does not match that requested. Please enter the word <strong><code>{this.state.pageTitle}</code></strong> to delete the page </p>
        </Message>
      </div>
    )
  }
  handleAccessChange = () => {
    if(this.state.restrict_to_admin === false){
      this.setState({restrict_to_admin: true})
    }
    if(this.state.restrict_to_admin === true){
      this.setState({restrict_to_admin: false})
    }
  }

  handleJunctionChange = () => {
    console.log('hello')
    console.log(this.state.junction_page)
    if(this.state.junction_page === false){
      this.setState({junction_page: true})
    }
    if(this.state.junction_page === true){
      this.setState({junction_page: false})
    }
  }

  renderAccessToggle = () => {
    // if(this.props.user['custom:level'] === 'basic'){
    //   return <div></div>
    // }
    return (
      <div style={{marginBottom: 20}}>
        <Divider />
          <h4>Restrict access to admin</h4>
            <Checkbox toggle
              onChange={this.handleAccessChange}
              checked={this.state.restrict_to_admin}
            />
          {this.state.restrict_to_admin === true ? (<p style={{color: 'green'}}>Restricted to admin</p>):(<p >Access to basic users</p>)}
      </div>
    )
  }



  renderJunctionPageToggle = () => {
    // if(this.props.user['custom:level'] === 'basic'){
    //   return <div></div>
    // }
    return (
      <div style={{marginBottom: 20}}>
        <Divider />
          <h4>Convert to junction page</h4>
            <Checkbox toggle
              onChange={this.handleJunctionChange}
              checked={this.state.junction_page}
            />
          {this.state.junction_page === true ? (<p style={{color: 'green'}}>Converted to Junction Page</p>):(<p >Normal Page</p>)}
      </div>
    )
  }


  render() {
    return (
      <Modal
        trigger={<Button basic onClick={this.handleOpen}>Edit Page Details</Button>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        >
        <Modal.Header>Edit Page Details</Modal.Header>
        <Modal.Content>
          {this.state.error ? (this.errorMessage()):(<div></div>)}
          <Form>
            <Form.Field>
            <h3>Please update page name</h3>
              <label>
                Update:
                <input
                  name="text"
                  type="text"
                  value={this.state.title}
                  onChange={this.handleChange}
                />
              </label>
              {this.renderAccessToggle()}
              {this.renderJunctionPageToggle()}
              <Button
                primary
                onClick={this.handleSubmit}
                content="Update"
              />
              <Button
                secondary
                onClick={this.handleClose}
                content="Close"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}
export default PageDelete;
