import React from "react";
import { Header, Segment, Grid, Input, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";

import PagesList from "./PagesList";
import PageNew from "./PageNew";
import MobileTiles from "../mobile/Tiles/MobileTiles";

import JunctionPageNew from "../junctionPage/JunctionPageNew";
import JunctionPagesList from "../junctionPage/JunctionPagesList";
import { fetchPages, fetchSections, fetchJunctionPages } from "../../actions";

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page_select: null,
      page_select_title: null,
      preview_selector: null,
      updatedSectons: null,
      user: this.props.user["custom:trust"],
      activeItem: "pages",
    };
    this.updateStateSections = this.updateStateSections.bind(this);
    // this.renderPageSection = this.renderPageSection(this);
  }

  componentDidMount() {
    this.props.fetchPages(this.state.user);
    // this.props.fetchSections(this.state.user);
    // this.props.fetchJunctionPages(this.state.user);
  }
  updateStateSections = async (page_id) => {
    const updatedSections = _.filter(this.props.sections, {
      section_id: page_id,
    });
    console.log(updatedSections);
    await this.setState({ updatedSectons: updatedSections });
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;

    let gfg = _.orderBy(this.props.pages, ["title"], ["asc"]);

    return (
      <div>
        <Grid columns="two" divided>
          <Grid.Row>
            <Grid.Column>
              <div style={{ marginTop: 15 }}>
                {/* <button onClick={() => this.props.fetchPages(this.state.user)}>
                  Test pages
                </button> */}
                <Header as="h2">Pages</Header>
                <Segment
                  style={{ textAlign: "center", background: "#efefef" }}
                  color="blue"
                >
                  <PageNew user={this.state.user} pages={this.props.pages} />
                </Segment>

                <PagesList
                  pages={gfg}
                  page_select={this.updatePageSelect}
                  user={this.props.user["custom:trust"]}
                  user_access={this.props.user["custom:level"]}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <MobileTiles
                origin={this.state.preview_selector}
                sections={this.state.updatedSectons}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pages: state.pages,
    sections: state.sections,
    user: state.user,
    junctionPages: state.junctionPages,
  };
};

export default connect(mapStateToProps, {
  fetchPages,
  fetchSections,
  fetchJunctionPages,
})(Pages);
