import React, { Component } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { Link } from "gatsby";
import { connect } from "react-redux";

import { update_Page, removePage } from "../../actions";
import PageDelete from "./PageDelete";
import PageEdit from "./PageEdit";

class PagesSection extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      id: props.page.id,
      title: props.page.title,
      type: props.page.type,
      page_select: props.page_select,
      page_id: props.page.page_id,
      user: props.user,
      junction_page: props.page.junction_page || false,
      activeIndex: 1,
    };
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };
  handleDelete = (event) => {
    this.props.removePage(this.props.page);
  };

  handleSubmit = () => {
    this.props.page_select(this.state.page_id, this.state.title);
  };

  render() {
    const { activeIndex } = this.state;
    return (
      <div key={this.props.page.id}>
        <Accordion styled style={{ marginTop: 10 }}>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
            // style={{ backgroundColor: "black" }}
          >
            <Icon name="dropdown" />
            {this.props.page.title}
            {this.props.page.user_id === "common" ? (
              <>
                {" "}
                <Icon name="share alternate" style={{ float: "right" }} />
              </>
            ) : (
              <></>
            )}
          </Accordion.Title>

          <Accordion.Content active={activeIndex === 0}>
            <h4>Title: {this.props.page.title}</h4>
            <Link
              className="ui button blue"
              to={"/sections?id=" + this.state.page_id}
            >
              Edit page
            </Link>
            {this.props.user_access !== "basic" ? (
              <div style={{ display: "inline" }}>
                <PageEdit
                  page={this.props.page}
                  updatePage={this.props.update_Page}
                  user={this.state.user}
                />
                <PageDelete
                  deleteTrust={this.handleDelete}
                  pageTitle={this.props.page.title}
                />
              </div>
            ) : (
              <></>
            )}
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }
}

export default connect(null, { update_Page, removePage })(PagesSection);
