import React from "react";
import { Form, Button, Modal, Divider, Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { addPage } from "../../actions";

class PageNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      modalOpen: false,
      user: props.user,
      type: "trust",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOpen = () => this.setState({ modalOpen: true, title: "" });
  handleClose = () => this.setState({ modalOpen: false });

  handleChange(event) {
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  }
  componentDidMount() {
    this.setState({ title: "" });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const total_pages = _.sortedIndex(this.props.pages);
    //const user_id = this.props.user["custom:trust"];
    var user;
    switch (this.state.type) {
      case "trust":
        user = this.state.user;
        break;
      case "common":
        user = "common";
        break;
      case "template":
        user = "template";
        break;
      default:
        break;
    }
    const params = {
      Item: {
        title: this.state.title,
        index_number: total_pages,
        user_id: user,
        junction_page: false,
        junction_data: JSON.stringify([
          { title: "Default", id: "", destinsation: "" },
        ]),
        page_id: this.state.user + ":" + uuidv4(),
      },
    };
    this.props.addPage(params, this.state.user);
    this.setState({ modalOpen: false });
  };

  setValue = (data) => {};

  render() {
    return (
      <Modal
        trigger={
          <Button primary onClick={this.handleOpen}>
            New Page
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={"mini"}
      >
        <Modal.Header>Add New Page</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>
                Name:
                <input
                  name="title"
                  type="text"
                  value={this.state.title}
                  onChange={this.handleChange}
                />
              </label>
              <Divider />
              <Form>
                <Form.Field>Selected page type:</Form.Field>
                <Form.Field>
                  <Checkbox
                    radio
                    label="Trust Specific"
                    name="checkboxRadioGroup"
                    value="trust"
                    checked={this.state.type === "trust"}
                    onChange={(e, data) => this.setState({ type: data.value })}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    radio
                    label="Common Page"
                    name="checkboxRadioGroup"
                    value="common"
                    checked={this.state.type === "common"}
                    onChange={(e, data) => this.setState({ type: data.value })}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    radio
                    label="Template Page"
                    name="checkboxRadioGroup"
                    value="template"
                    checked={this.state.type === "template"}
                    onChange={(e, data) => this.setState({ type: data.value })}
                  />
                </Form.Field>
              </Form>
              <Divider />
              <Button primary onClick={this.handleSubmit} content="Save" />
              <Button secondary onClick={this.handleClose} content="Close" />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
export default connect(null, { addPage })(PageNew);
